import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const title = "STORY";

export default function Story({}) {
  const styles = {
    title: {
      fontSize: "var(--title-size)",
      fontWeight: "var(--title-weight)",
      textAlign: "center",
      margin: 80,
      color: "var(--accent-text)",
      fontFamily: "var(--title-font)"
    },
    container: {
      width: "100%",
      borderSpacing: 20
    },
    image: {
      borderRadius: 20,
      color: "#fff",
      display: "flex",
      margin: "auto"
    },
    textContainer: {
      padding: 20,
      borderRadius: 10,
      display: "table-cell",
      // width: "37%",
      verticalAlign: "middle",
      margin: "auto",
      width: "100%",
      marginRight: "20%",
      marginLeft: "10%",
      color: "var(--accent-text)",
      fontFamily: "var(--description-font)",
      fontWeight: 700
    },
    header: {
      fontSize: "var(--description-size)"
    },
    text: {
      fontSize: "var(--description-size)",
      textAlign: "center"
    }
  };

  return (
    <div id="story">
      <h2 style={styles.title}>{title}</h2>
      <div style={styles.container} id={"story-container"}>
        <div id="story-text-container" style={styles.textContainer}>
          <p style={styles.text}>
            {`Battle Academy Ducks is a hand drawn art collection on the Solana blockchain inspired by anime culture. Every BAD hero has special characteristics, skillsets and abilities, while a couple of them have very rare powers. They are divided in different realms and also different races, where some of them act like heroes and some as villains. 
              These powerful heroes have anime based powers, some of them can master natures components, some of them can use magical weapons, etc`}
          </p>
        </div>
      </div>
    </div>
  );
}
