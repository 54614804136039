import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HeaderMenu({ show, isMobileScreen, onPress }) {
  const menu = [
    {
      name: "Mint",
      id: "mint"
    },
    {
      name: "Whitelist",
      id: "whitelist"
    },
    {
      name: "Story",
      id: "story"
    },
    {
      name: "Vision",
      id: "roadmap",
      id_2: "roadmap-mobile"
    },
    {
      name: "FAQ",
      id: "faq"
    }
  ];

  const styles = {
    menuItemContainer: {
      marginhorizontal: 15,
      marginLeft: 40,
      marginRight: 40
    },
    menuItem: {
      fontSize: "var(--header-menu-size)",
      fontFamily: "var(--menu-item-font)",
      // Copperplate, Luminari, Marker Felt,
      //  Trebuchet MS, Palatino, Courier New
      fontWeight: 900,
      color: "#fff"
    }
  };

  const executeScroll = (id) => {
    document
      .getElementById(id)
      ?.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return (
    <>
      {(show || !isMobileScreen) && (
        <div id="header-menu-container" onClick={onPress}>
          {menu?.map((item) => {
            return (
              <div
                key={item?.id}
                className={"menu-element"}
                onClick={() => {
                  setTimeout(() => {
                    executeScroll(item?.id_2);
                    executeScroll(item?.id);
                  }, 200);
                }}
                style={styles.menuItemContainer}
              >
                <span style={styles.menuItem}>{item.name}</span>
              </div>
            );
          })}
          {/* <div style={styles.menuItemContainer}>
        <p>{"|"}</p>
      </div>
      <div
        className={"menu-element"}
        onClick={() => executeScroll("")}
        style={styles.menuItemContainer}
      >
        <span style={styles.menuItem}>{"Connect"}</span>
      </div> */}
        </div>
      )}
    </>
  );
}
