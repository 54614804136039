const constants = {
  SOCIAL: {
    X_URL: "https://x.com/",
    TELEGRAM_URL: "https://t.me/",
    DISCORD_URL: "https://discord.gg/",
    X_USERNAME: "BattleAcDucks",
    TELEGRAM_USERNAME: "",
    DISCORD_CHANNEL: ""
  }
};
export default constants;
