import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import RoadMap from "./components/RoadMap";
import "./styles.css";
import HeaderMenu from "./components/HeaderMenu";
import Accordions from "./components/Accordions";
import Story from "./components/Story";
import SocialMedia from "./components/SocialMedia";
import Start from "./components/Start";
import Mint from "./components/Mint";
import RoadMapMobile from "./components/RoadMapMobile";
import Logo from "./components/Logo";
import MenuIcon from "./components/MenuIcon";
import ReactGa from "react-ga";
import Whitelist from "./components/Whitelist";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import {
  WalletProvider,
  ConnectionProvider
} from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import {
  // GlowWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  MathWalletAdapter
} from "@solana/wallet-adapter-wallets";
import { ReferralSection } from "./components/ReferralSection";
import Spacer from "./components/Spacer";
import { FollowOnTwitter } from "./components/FollowOnTwitter";
import constants from "./utils/constants";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  // ^ state
  const [reverse, setReverse] = React.useState(null);
  const [paths, setPaths] = useState([]);
  const [pathStyle, setPathStyle] = useState({});
  const [pathStyleReverse, setPathStyleReverse] = useState({});
  const [menuToggleShow, setMenuToggleShow] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(null);

  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false
  });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      // new GlowWalletAdapter(),
      new MathWalletAdapter()
    ],
    []
  );

  const theme = extendTheme({
    config: {
      initialColorMode: "dark"
    }
  });

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const mobileScreenWidth = 767;

  // ^ - refs
  let roadmapRef = useRef(null);
  let refList = [];
  refList.push(roadmapRef);

  // ^ nft functions
  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  // ^ - other functions

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  };

  const setPathStylings = (x, y) => {
    console.log(x, y);
    setPathStyle({
      transform: `translate(${x}px, ${y}px)`
    });
    setPathStyleReverse();
  };

  const _onMouseMove = () => {
    const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
    window.addEventListener("mousemove", setFromEvent);
    // console.log(" POS :  ", position);

    // setPathStylings(position.x, position.y);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  // ^ - effects
  useEffect(() => {
    if (window.screen.width > mobileScreenWidth) {
      setIsMobileScreen(false);
    } else {
      setIsMobileScreen(true);
    }
  }, []);

  // useEffect(() => {
  //   // ? initialize path elements
  //   let tempPaths = [];
  //   for (let i = 0; i < 10; i++) {
  //     let randRgb = getRandomInt(0, 150);
  //     tempPaths.push(
  //       <path
  //         d={`M${getRandomInt(50, 350)},${getRandomInt(0, 225)}L${getRandomInt(
  //           0,
  //           225
  //         )},${getRandomInt(40, 200)}L${getRandomInt(80, 200)},${getRandomInt(
  //           80,
  //           350
  //         )}Z`}
  //         fill={`rgb(${randRgb}, ${randRgb}, ${randRgb})`}
  //         fill-opacity={`${getRandomInt(1, 100) / 100}`}
  //       />
  //     );
  //   }
  //   setPaths(tempPaths);
  //   console.log("useEffect PATHS:  ", paths);
  // }, []);

  useEffect(() => {
    if (windowSize.width > mobileScreenWidth) {
      setIsMobileScreen(false);
    } else {
      setIsMobileScreen(true);
    }
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    handleResize(); // ? Initial call to set window size

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    getConfig();

    // ? init video background
    var video = document.getElementById("video-bg");
    video.play();

    // ? init Google Analytics
    ReactGa.initialize("G-2KPRW4RX8L");
    ReactGa.pageview("/");
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  // ^ - variables
  const connectText = "CONNECT WALLET";
  const name = "Logo here";

  // ^ - styles
  const styles = {
    containerOpacity: {
      position: "relative",
      backgroundColor: "#000",
      height: window.screen.height,
      opacity: 0.9,
      zIndex: 2,
      width: "100%",
      overflow: "hidden"
    }
  };

  return (
    <ConnectionProvider endpoint={clusterApiUrl("mainnet-beta")}>
      <ChakraProvider theme={theme}>
        <WalletProvider wallets={wallets} autoConnect>
          <s.Screen style={{ overflowX: "hidden" }}>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            <video playsInline autoPlay muted loop id="video-bg">
              <source src="/assets/bad_bg_image.mp4" type="video/mp4" />
              <source src="/assets/bad_bg_image.webm" type="video/webm" />
            </video>
            <div style={menuToggleShow ? styles.containerOpacity : null}>
              <s.Header id="header" style={{ padding: 10, marginTop: 20 }}>
                <Logo />
                <HeaderMenu
                  show={menuToggleShow}
                  isMobileScreen={isMobileScreen}
                  onPress={() => setMenuToggleShow(false)}
                />

                <s.SocialMediaContainer>
                  <SocialMedia
                    show={menuToggleShow}
                    isMobileScreen={isMobileScreen}
                  />
                </s.SocialMediaContainer>
                <div
                  id="menu-icon-mobile"
                  onClick={() => {
                    setMenuToggleShow(!menuToggleShow);
                  }}
                >
                  <MenuIcon toggleOn={menuToggleShow} />
                </div>
              </s.Header>
              {
                // * New trasnaprent header
              }
              <s.Container
                className={"content-container"}
                style={{ zIndex: 3 }}
                ai={"center"}
              ></s.Container>
              <div
                style={{ display: "flex", flexDirection: "column", zIndex: 6 }}
              >
                <Spacer size={400} />
                <FollowOnTwitter
                  twitterUrl={`${constants.SOCIAL.X_URL}${constants.SOCIAL.X_USERNAME}`}
                  twitterHandle={constants.SOCIAL.X_USERNAME}
                />
                <Start menuToggleShow={menuToggleShow} />
                <Mint />
                <ReferralSection />
                <Story />
                <RoadMap />
                <RoadMapMobile />
                <Accordions />
              </div>
              <div style={{ marginBottom: 200 }} />

              <s.Footer id="footer">
                <div
                  style={{
                    display: "flex",
                    marginRight: "auto",
                    fontSize: "2vh",
                    color: "var(--secondary-text)"
                  }}
                >
                  <p className="footer-text">
                    {"© 2024-25. Battle Academy Ducks. All rights reserved"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "2vh",
                    color: "var(--secondary-text)"
                  }}
                >
                  <p className="footer-text">{"Join us on "}</p>
                </div>
                <s.SocialMediaContainerBottom id="social-container-bottom">
                  <SocialMedia
                    show={true}
                    renderOpensea={false}
                    marginRight={20}
                  />
                </s.SocialMediaContainerBottom>
                <div id="fogwrapper">
                  <div id="foglayer_01" className="fog">
                    <div className="image01"></div>
                    <div className="image02"></div>
                  </div>
                  <div id="foglayer_02" className="fog">
                    <div className="image01"></div>
                    <div className="image02"></div>
                  </div>
                  <div id="foglayer_03" className="fog">
                    <div className="image01"></div>
                    <div className="image02"></div>
                  </div>
                </div>
              </s.Footer>
            </div>
          </s.Screen>
        </WalletProvider>
      </ChakraProvider>
    </ConnectionProvider>
  );
}

export default App;
