import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function MenuIcon({ toggleOn }) {
  const transform = () => {
    document.getElementById("menu-icon-container")?.classList.toggle("change");
  };

  useEffect(() => {
    transform();
  }, [toggleOn]);

  return (
    <div className="menu-icon-container">
      <div className={!toggleOn ? "bar1" : `change-bar1`}></div>
      <div className={!toggleOn ? "bar2" : `change-bar2`}></div>
      <div className={!toggleOn ? "bar3" : `change-bar3`}></div>
    </div>
  );
}
