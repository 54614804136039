import React from "react";

export default function RoadMapMobile({ textColor = "#fff" }) {
  const title = "VISION";

  const description =
    "Below is the vision of our team, with our primary goal being creating a brand while providing value to the community. We're committed to continue building on our promises after the sale.";

  const styles = {
    container: {
      marginTop: 50,
      marginBottom: 50,
      color: textColor,
      fontFamily: "var(--description-font)"
    },
    title: {
      fontSize: "var(--title-size)",
      fontWeight: "var(--title-weight)",
      margin: 40,
      marginTop: 200,
      textAlign: "center",
      fontFamily: "var(--title-font)"
    },
    description: {
      fontSize: "var(--description-size)",
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 90,
      fontFamily: "var(--description-font)"
    },

    progressBar: {
      marginLeft: "3vh",
      marginRight: "3vh"
    },
    // progressBarLine: {
    //   position: "relative",
    //   width: 4,
    //   // backgroundColor: "#343434",
    //   backgroundColor: textColor,
    //   marginLeft: 13,
    //   marginRight: 13,
    // },
    // stepCircle: {
    //   position: "absolute",
    //   display: "flex",
    //   left: -13,
    //   // backgroundColor: "#343434",
    //   backgroundColor: textColor,
    //   borderRadius: "50%",
    //   width: 30,
    //   height: 30,
    // },
    // stepNumber: {
    //   fontSize: "var(--roadmap-number-size)",
    //   color: "#000",
    //   verticalAlign: "middle",
    //   margin: "auto",
    // },

    stepLeft: {
      // width: "50%",
      // textAlign: "right",
      // paddingRight: 100,
    },
    stepTextLeft: {
      // marginLeft: 100,
      // height: 200,
    },
    stepTextLeftTitle: {
      marginTop: "3vh",
      fontSize: "var(--roadmap-header-size)",
      fontWeight: "bold"
      // fontFamily: "Everett-Medium",
    },
    stepTextLeftDesc: {
      fontSize: "var(--roadmap-desc-size)"
      // fontFamily: "Everett-Light",
    },

    stepRight: {
      // width: "50%",
      // textAlign: "left",
      // paddingLeft: 100,
    },
    stepTextRight: {
      // alignSelf: "end",
      // marginRight: 100,
    },
    stepTextRightTitle: {
      marginTop: "3vh",
      fontSize: "var(--roadmap-header-size)",
      fontWeight: "bold"
      // fontFamily: "Everett-Medium",
    },
    stepTextRightDesc: {
      fontSize: "var(--roadmap-desc-size)"
      // fontFamily: "Everett-Light",
    }
  };

  const steps = [
    {
      title: "CHARITY",
      description: "DONATIONS TO CHARITY CHOSEN BY THE COMMUNITY"
    },
    {
      title: "COMMUNITY TOOLS",
      description:
        "CREATION OF ON-CHAIN TOOL FOR HOLDERS TO FOLLOW EACH OTHER ON SOCIAL MEDIA"
    },
    {
      title: "METAVERSE",
      description:
        "3D VERSIONS OF BATTLE ACADEMY DUCKS WILL BE CREATED FOR HOLDERS TO BE PLAYABLE WITHIN THE SANDBOX METAVERSE"
    },
    {
      title: "EXCLUSIVE BATTLE ACADEMY DUCKS",
      description:
        "A DROP OF 5 LEGENDARY BATTLE ACADEMY DUCKS NFTS THAT FEATURE TRAITS BASED ON THE REQUESTS OF 5 LUCKY HOLDERS"
    },
    {
      title: "GIVEAWAYS",
      description:
        "20 BATTLE ACADEMY DUCKS BOUGHT FROM SECONDARY MARKET WILL BE GIVEN AWAY TO RANDOM HOLDERS"
    },
    {
      title: "TEAM GROWTH",
      description:
        "HIRE SOCIAL MEDIA MANAGER, COMMUNITY MANAGERS AND DEVELOPERS TO EXPAND OUR VISION"
    },
    {
      title: "MARKETPLACE",
      description:
        "CREATION OF OUR OWN MARKETPLACE TO FUND THE DAO AND GET RID OF THE 3RD PARTY FEES"
    },
    {
      title: "PRIVATE GROUPS",
      description: "VIP ACCESS TO HOLDERS ONLY PRIVATE GROUPS"
    },
    {
      title: "DAO CREATION",
      description: "FORMING A DAO FUNDED BY THE TEAM"
    },
    {
      title: "RARITY",
      description:
        "LIST THE COLLECTION ON RARITY TOOLS AND RARITY SNIPER FOR BETTER UNDERSTANDING OF RANKINGS"
    },
    {
      title: "REWARD SYSTEM",
      description: "CREATION OF A REWARD SYSTEM FOR HOLDERS"
    }
  ];

  return (
    <div style={styles.container} id={"roadmap-mobile"}>
      {
        // * TITLE
      }
      <p style={styles.title}>{title}</p>

      {
        // * DESCRIPTION OF VISION
      }
      <p id="roadmap-description" style={styles.description}>
        {description}
      </p>

      {
        // * ROADMAP PROGRESS BAR
      }
      <div style={styles.progressBar}>
        {
          // * Steps on Left side
        }
        <div style={styles.stepLeft}>
          <div
            style={{
              ...styles.stepTextLeft
            }}
          >
            <h2 style={styles.stepTextLeftTitle}>{steps[0].title}</h2>
            <p style={styles.stepTextLeftDesc}>{steps[0].description}</p>
          </div>
          <div style={{ ...styles.stepTextLeft }}>
            <h2 style={styles.stepTextLeftTitle}>{steps[2].title}</h2>
            <p style={styles.stepTextLeftDesc}>{steps[2].description}</p>
          </div>
          <div style={{ ...styles.stepTextLeft }}>
            <h2 style={styles.stepTextLeftTitle}>{steps[4].title}</h2>
            <p style={styles.stepTextLeftDesc}>{steps[4].description}</p>
          </div>
          <div style={{ ...styles.stepTextLeft }}>
            <h2 style={styles.stepTextLeftTitle}>{steps[6].title}</h2>
            <p style={styles.stepTextLeftDesc}>{steps[6].description}</p>
          </div>
          <div style={{ ...styles.stepTextLeft }}>
            <h2 style={styles.stepTextLeftTitle}>{steps[8].title}</h2>
            <p style={styles.stepTextLeftDesc}>{steps[8].description}</p>
          </div>
        </div>

        {/* <div style={styles.progressBarLine} id="progress-bar-line">
          <div style={{ ...styles.stepCircle, ...{ top: 10 } }}>
            <p style={styles.stepNumber}>{"1"}</p>
          </div>
          <div style={{ ...styles.stepCircle, ...{ top: 210 } }}>
            <p style={styles.stepNumber}>{"2"}</p>
          </div>
          <div style={{ ...styles.stepCircle, ...{ top: 420 } }}>
            <p style={styles.stepNumber}>{"3"}</p>
          </div>
          <div style={{ ...styles.stepCircle, ...{ top: 630 } }}>
            <p style={styles.stepNumber}>{"4"}</p>
          </div>
          <div style={{ ...styles.stepCircle, ...{ top: 840 } }}>
            <p style={styles.stepNumber}>{"5"}</p>
          </div>
          <div style={{ ...styles.stepCircle, ...{ top: 1050 } }}>
            <p style={styles.stepNumber}>{"6"}</p>
          </div>
          <div style={{ ...styles.stepCircle, ...{ top: 1260 } }}>
            <p style={styles.stepNumber}>{"7"}</p>
          </div>
          <div style={{ ...styles.stepCircle, ...{ top: 1470 } }}>
            <p style={styles.stepNumber}>{"8"}</p>
          </div>
          <div style={{ ...styles.stepCircle, ...{ top: 1680 } }}>
            <p style={styles.stepNumber}>{"9"}</p>
          </div>
          <div style={{ ...styles.stepCircle, ...{ top: 1890 } }}>
            <p style={styles.stepNumber}>{"10"}</p>
          </div>
          <div style={{ ...styles.stepCircle, ...{ top: 2100 } }}>
            <p style={styles.stepNumber}>{"11"}</p>
          </div>
        </div> */}
        {
          // * Steps on Right side
        }
        <div style={styles.stepRight}>
          <div style={{ ...styles.stepTextRight }}>
            <h2 style={styles.stepTextRightTitle}>{steps[1].title}</h2>
            <p style={styles.stepTextRightDesc}>{steps[1].description}</p>
          </div>
          <div style={{ ...styles.stepTextRight }}>
            <h2 style={styles.stepTextRightTitle}>{steps[3].title}</h2>
            <p style={styles.stepTextRightDesc}>{steps[3].description}</p>
          </div>
          <div style={{ ...styles.stepTextRight }}>
            <h2 style={styles.stepTextRightTitle}>{steps[5].title}</h2>
            <p style={styles.stepTextRightDesc}>{steps[5].description}</p>
          </div>
          <div style={{ ...styles.stepTextRight }}>
            <h2 style={styles.stepTextRightTitle}>{steps[7].title}</h2>
            <p style={styles.stepTextRightDesc}>{steps[7].description}</p>
          </div>
          <div style={{ ...styles.stepTextRight }}>
            <h2 style={styles.stepTextRightTitle}>{steps[9].title}</h2>
            <p style={styles.stepTextRightDesc}>{steps[9].description}</p>
          </div>
          <div style={{ ...styles.stepTextRight }}>
            <h2 style={styles.stepTextRightTitle}>{steps[10].title}</h2>
            <p style={styles.stepTextRightDesc}>{steps[10].description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
