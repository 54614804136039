import React from "react";
import { FaTelegramPlane, FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import constants from "../utils/constants";

const SocialMediaShare = ({ referralLink }) => {
  const encodedLink = encodeURIComponent(referralLink);

  const { X_URL, TELEGRAM_URL, DISCORD_URL } = constants.SOCIAL;

  const socialMediaLinks = {
    twitter: `${X_URL}intent/tweet?text=Sign%20up%20using%20my%20referral%20link:%20${encodedLink}`,
    telegram: `${TELEGRAM_URL}/share/url?url=${encodedLink}&text=Sign%20up%20using%20my%20referral%20link`,
    discord: `${DISCORD_URL}` // TODO : add the encodedLink and message here
  };

  return (
    <div style={styles.container}>
      <a
        href={socialMediaLinks.twitter}
        target="_blank"
        rel="noopener noreferrer"
        style={styles.button}
      >
        <FaXTwitter style={styles.icon} />
      </a>
      <a
        href={socialMediaLinks.telegram}
        target="_blank"
        rel="noopener noreferrer"
        style={styles.button}
      >
        <FaTelegramPlane style={styles.icon} />
      </a>
      <a
        href={socialMediaLinks.discord}
        target="_blank"
        rel="noopener noreferrer"
        style={styles.button}
      >
        <FaDiscord style={styles.icon} />
      </a>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px"
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 20px",
    margin: "0 10px",
    backgroundColor: "#007bff",
    color: "white",
    borderRadius: "4px",
    textDecoration: "none",
    fontSize: "16px",
    cursor: "pointer"
  },
  icon: {
    marginRight: "5px",
    width: "21px",
    height: "21px"
  }
};

export default SocialMediaShare;
