import React, { useEffect, useState } from "react";

export default function Question({
  title,
  description,
  index,
  indexOpened,
  onPress
}) {
  const [expanded, setExpanded] = useState(false);

  const dropdownIcon = "/assets/dropdown.svg";

  const styles = {
    sectionContainer: {
      borderBottom: "1px solid grey",
      paddingBottom: 10,
      marginBottom: "2vh"
    },
    row: {
      display: "flex",
      justifyContent: "space-between"
    },
    heading: {
      fontSize: "var(--question-size)",
      marginBottom: 10,
      fontWeight: 700,
      fontFamily: "var(--description-font)"
    },
    description: {
      fontSize: "var(--answer-size)",
      color: "#d3d3d3",
      fontWeight: 400,
      fontFamily: "var(--faq-answer-font)"
    },
    descriptionClosed: {
      display: "none"
    },
    pseudoIcon: {
      fontSize: "5vh"
    },
    minusIcon: {
      fontSize: "7vh"
    },
    questionClosed: {
      height: "3vh"
    },
    questionOpened: {
      transform: "rotate(180deg)"
    }
  };

  const close = () => {
    setExpanded(false);
  };

  useEffect(() => {
    if (indexOpened !== index) {
      close();
    }
  }, [index, indexOpened]);

  return (
    <div id={"faq"} style={styles.sectionContainer}>
      <div
        className="accordion-title-container"
        style={styles.row}
        onClick={() => {
          onPress(index);
          setExpanded(!expanded);
        }}
      >
        <h2 style={styles.heading}>{title}</h2>
        <p
          style={
            ({ ...styles.pseudoIcon },
            expanded
              ? { display: "none" }
              : {
                  display: "inline-block",
                  fontSize: "var(--description-size)",
                  marginTop: "auto",
                  marginBottom: "auto"
                })
          }
        >
          <img src={dropdownIcon} alt={""} style={styles.questionClosed} />
        </p>
        <p
          style={
            ({ ...styles.pseudoIcon },
            !expanded
              ? { display: "none" }
              : { display: "inline-block", fontSize: "28px" })
          }
        >
          <img src={dropdownIcon} alt={""} style={styles.questionOpened} />
        </p>
      </div>
      <p style={expanded ? styles.description : styles.descriptionClosed}>
        {description}
      </p>
    </div>
  );
}
