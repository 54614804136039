import React from "react";

function Spacer({ tiny, micro, small, regular, large, extraLarge, size }) {
  const getHeight = () => {
    if (!!tiny) return "8px";
    else if (!!micro) return "12px";
    else if (!!small) return "16px";
    else if (!!regular) return "24px";
    else if (!!large) return "32px";
    else if (!!extraLarge) return "48px";
    else if (!!size) return `${size}px`;
    else return "0px";
  };

  return <div style={{ height: getHeight() }}></div>;
}

export default Spacer;
