import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import socialMediaConfig from "../staticData/socialMedia.json";

export default function SocialMedia({
  renderOpensea = true,
  show,
  isMobileScreen,
  onPress,
  marginRight = 200
}) {
  const styles = {
    container: {
      display: "flex",
      zIndex: 3,
      textAlign: "center"
    },
    socialItem: {
      display: "flex",
      marginRight: "auto",
      marginLeft: "auto"
    },
    iconContainer: {
      height: "100%",
      display: "flex",
      marginRight: 15,
      marginLeft: 15
    },
    test: {
      opacity: "0.5",
      transition: "opacity 0.35s ease-in-out"
    }
  };

  return (
    <>
      {(show || !isMobileScreen) && (
        <div style={styles.container} onClick={onPress}>
          {socialMediaConfig?.data.map((media) => {
            return (
              (media.name !== "Opensea" || renderOpensea) && (
                <div style={styles.socialItem} key={media.name}>
                  <a
                    rel="noopener noreferrer"
                    href={media.active ? media.source : null}
                    target="_blank"
                  >
                    <div style={styles.iconContainer}>
                      <img
                        src={media.icon}
                        alt={""}
                        style={media?.style}
                        className={
                          media.active
                            ? "social-icon-active"
                            : "social-icon-inactive"
                        }
                      />
                    </div>
                  </a>
                </div>
              )
            );
          })}
        </div>
      )}{" "}
    </>
  );
}
