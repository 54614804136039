import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  // background-color: var(--primary);
  // background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  // background-size: cover;
  // background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  height: ${({ height }) => (height ? height : 30)};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  // overflow: scroll;
`;

export const TextTitle = styled.span`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
  font-family: Copperplate;
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const Header = styled.div`
  height: ${({ height }) => (height ? height : "80px")};
  // background-color: var(--primary);
  // position: fixed;
  width: 100%;
  z-index: 100;
  // -webkit-box-shadow: 3px 6px 3px -2px rgba(0, 0, 0, 0.3);
`;

export const Footer = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  // display: flex;
  height: ${({ height }) => (height ? height : "80px")};
  padding-left: 4vh;
  padding-right: 4vh;
  // background-color: white;
  // box-shadow: 4px 4px 80px #808080;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
`;

export const SocialMediaContainer = styled.div`
  margin-left: auto;
  margin-right: 5vw;
  height: 100%;
  width: fit-content;
  align-self: center;
  display: flex;
  text-align: center;
  @media only screen and (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const SocialMediaContainerBottom = styled.div`
  height: 100%;
  width: fit-content;
  align-self: center;
  display: flex;
  text-align: center;
  @media only screen and (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const SocialIconContainer = styled.div`
  margin-right: 15px;
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
`;
