import React, { useState } from "react";
import Question from "./Question";

export default function Accordions({ textColor = "#fff" }) {
  const title = "FAQ";

  const [indexOpened, setIndexOpened] = useState(null);

  const questions = [
    {
      title: "How many Battle Academy Ducks will be created?",
      description: "TBA"
    },
    {
      title: "When is the minting launch date?",
      description: "TBA"
    },
    {
      title: "Is there going to be a whitelist process?",
      description:
        "Yes, wait our announcement to find out first when it goes live!"
    },
    {
      title: "How much does a Battle Academy Duck cost?",
      description: "TBA"
    },
    {
      title: "What is the team's royatly charged?",
      description: "2.5%"
    },
    {
      title: "Is there a premint for the team?",
      description:
        "No, we don't support such actions. The team will buy from secondary market with the capital gained."
    },
    {
      title: "When is the reveal date?",
      description: "The reveal date will take place 24 hours after the sale."
    },
    {
      title: "What type of wallet is needed for the mint?",
      description:
        "The wallet needed will be any Solana wallet with enough SOL for the mint."
    },
    {
      title: "Where can I mint?",
      description: "TBA"
    },
    {
      title: "Do you have more questions?",
      description: "Join our Socials to learn more and ask any other questions."
    },
    {
      title: "Quack Quack?",
      description: "Yes motherfucker!"
    }
  ];

  const styles = {
    container: {
      display: "block",
      height: 600,
      color: textColor
    },
    title: {
      fontSize: "var(--title-size)",
      fontWeight: "var(--title-weight)",
      marginTop: "6vh",
      marginBottom: "6vh",
      textAlign: "center",
      fontFamily: "var(--title-font)"
    }
  };

  return (
    <div style={styles.container} id="faq">
      <p style={styles.title}>{title}</p>
      {questions.map((faq, index) => {
        return (
          <Question
            key={faq.title}
            title={faq.title}
            description={faq.description}
            index={index}
            indexOpened={indexOpened}
            onPress={() => {
              setIndexOpened(index);
            }}
          />
        );
      })}
    </div>
  );
}
