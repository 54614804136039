import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  addToDatabase,
  getReferralCount,
  getReferralLink
} from "../database/firebaseService";
import { generateReferralLink } from "../referral/referralService";
import {
  getDatabase,
  ref,
  push,
  get,
  query,
  orderByChild,
  equalTo,
  update
} from "firebase/database";
import zeroCounterImage from "../assets/zero-counter-image.png";
import axios from "axios";
import bowser from "bowser"; // Import bowser
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from "react-ga";
import FloatingLabelInput from "./FloatingLabelInput";
import ReferralLink from "./ReferralLink";
import Label from "./Label";
import palette from "../styles/palette";
import WhitelistChecker from "./WhitelistChecker";
import Spacer from "./Spacer";
import SocialMediaShare from "./SocialMediaShare";
import constants from "../utils/constants";
import { useWallet } from "@solana/wallet-adapter-react";
import Web3 from "web3";
import { Heading, VStack } from "@chakra-ui/react";
import Wallets from "./Wallets"; // & use this in case of error --> import dynamic from "next/dynamic"; const Wallets = dynamic(() => import("../Wallets"), { ssr: false });

ReactGA.initialize("G-M0YVPQXM3N");

const { ethereum } = window;
let web3 = new Web3(ethereum);

export default function Whitelist() {
  const [activeScreen, setActiveScreen] = useState("whitelist");
  const [solanaAddress, setSolanaAddress] = useState("");
  const [twitterHandle, setTwitterHandle] = useState("");
  const [message, setMessage] = useState("");
  const [referralLink, setReferralLink] = useState("mock-referral-link.com");
  const [referredFrom, setReferredFrom] = useState(window?.location?.href);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [referralSolanaAddress, setReferralSolanaAddress] = useState("");
  const [referrals, setReferrals] = useState([]);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [whitelistMessage, setWhitelistMessage] = useState("");
  const [referralMessage, setReferralMessage] = useState("");
  const [whitelistReferralLink, setWhitelistReferralLink] = useState("");
  const [checkReferralLink, setCheckReferralLink] = useState("");
  const [referralCount, setReferralCount] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaVisible, setRecaptchaVisible] = useState(true);
  const [referralLinkCopied, setReferralLinkCopied] = useState(false);
  const [isAddressWhitelisted, setIsAddressWhitelisted] = useState(null);
  const [account, setAccount] = useState(null);
  const [network, setNetwork] = useState(null);
  const [connectWalletErrorMessage, setConnectWalletErrorMessage] =
    useState("");

  const { wallets } = useWallet();

  console.log(wallets);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [activeScreen]);

  useEffect(() => {
    const checkWhitelistStatus = async () => {
      try {
        const whitelisted = await false; // TODO : put function here that will check if the address is whitelisted
        setIsAddressWhitelisted(whitelisted);
      } catch (error) {
        console.error("Error checking whitelist status:", error);
        setIsAddressWhitelisted(false); // ? Assume not whitelisted in case of error
      }
    };

    if (solanaAddress) {
      checkWhitelistStatus();
    }
  }, [solanaAddress]);

  const recaptchaHandler = (token) => {
    console.log("ReCAPTCHA token:", token);
    setRecaptchaToken(token);
  };

  const handleCheckReferrals = async () => {
    if (referralSolanaAddress) {
      const database = getDatabase();
      const entriesRef = ref(database, "entries");
      const solanaQuery = query(
        entriesRef,
        orderByChild("solanaAddress"),
        equalTo(referralSolanaAddress)
      );
      const snapshot = await get(solanaQuery);

      if (snapshot.exists()) {
        const entryData = snapshot.val();
        const entryKey = Object.keys(entryData)[0]; // Assuming only one entry per Solana address
        const { referralLink } = entryData[entryKey];

        if (referralLink) {
          ReactGA.event({
            category: "Button",
            action: "Clicked Check Referrals",
            label: "Check Referrals Button"
          });
          const count = await getReferralCount(referralLink);
          setReferralCount(count);
          setReferralMessage(``);
          setReferrals([]);
          setCheckReferralLink(referralLink); // Set check referral link here
          setSubmitButtonClicked(true);
        } else {
          setReferralMessage("No referral link found for this Solana address.");
          setReferrals([]);
          setCheckReferralLink(null);
          setSubmitButtonClicked(false);
        }
      } else {
        setReferralMessage("No entries found for this Solana address.");
        setReferrals([]);
        setCheckReferralLink(null);
        setSubmitButtonClicked(false);
      }
    } else {
      setReferralMessage("Please enter a Solana address.");
    }
  };

  const handleSubmit = async () => {
    // TODO: Remove comments
    // if (solanaAddress.length !== 44) {
    //   setWhitelistMessage("Enter a valid Solana address!");
    //   return;
    // }

    // Define getDeviceType function outside the component
    const getDeviceType = () => {
      const userAgent = navigator.userAgent.toLowerCase();

      if (userAgent.match(/mobile/i)) {
        return "Mobile";
      } else if (userAgent.match(/tablet/i)) {
        return "Tablet";
      } else if (userAgent.match(/ipad/i)) {
        return "iPad";
      } else {
        return "Desktop/Laptop";
      }
    };

    // Get browser information using bowser library
    const browserInfo = bowser.parse(navigator.userAgent);

    // TODO: Remove comment
    if (solanaAddress && twitterHandle) {
      // && recaptchaToken) {
      try {
        // Fetch IPv4 address
        const ipv4Response = await axios.get(
          "https://api.ipify.org?format=json"
        );
        const ipv4 = ipv4Response.data.ip || "Unknown"; // Default to 'Unknown' if IP is not available

        // Fetch IPv6 address
        const ipv6Response = await axios.get(
          "https://api64.ipify.org?format=json"
        );
        const ipv6 = ipv6Response.data.ip || "Unknown"; // Default to 'Unknown' if IP is not available

        // Fetch additional information using IPv4 address (as most geolocation services prefer IPv4)
        const geoResponse = await axios.get(`https://ipapi.co/${ipv4}/json/`);
        const { country_name, city, region, isp } = geoResponse.data || {};

        // Default values for missing information
        const country = country_name || "Unknown";
        const userCity = city || "Unknown";
        const userRegion = region || "Unknown";
        const userISP = isp || "Unknown";

        // Get device type using the defined function
        const deviceType = getDeviceType();

        // Gather browser and OS information
        const browser = browserInfo.browser.name || "Unknown"; // Extract browser information using bowser
        const os = browserInfo.os.name || "Unknown"; // Extract OS information using bowser

        // Gather timestamp
        const timestamp = new Date();

        // Generate referral link
        const referralLink = generateReferralLink();

        // Save all information to database
        const result = await addToDatabase(
          solanaAddress,
          twitterHandle,
          {
            ipv4,
            ipv6,
            country,
            city: userCity,
            region: userRegion,
            isp: userISP,
            deviceType,
            browser,
            os
          },
          timestamp,
          referralLink,
          referredFrom
        );

        setWhitelistMessage(result);

        if (result === "Submitted successfully!") {
          setWhitelistReferralLink(referralLink);
          setIsSubmitted(true); // Set the state to indicate the form is submitted
          setRecaptchaVisible(false); // Hide ReCAPTCHA on successful submission
          ReactGA.event({
            category: "Button",
            action: "Clicked Submit - Whitelist",
            label: "Submit Button - Whitelist"
          });
        }
      } catch (error) {
        setWhitelistMessage("Error fetching information: " + error.message); // Provide more detailed error message
        console.error("Error fetching information:", error);
      }
    } else {
      setWhitelistMessage("All fields are required!");
    }
  };

  const connectWallet = async () => {
    if (!web3) {
      setConnectWalletErrorMessage(
        "No wallet provider found. Install MetaMask or Rabby!"
      );
      return;
    }

    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      });
      setAccount(accounts[0]);

      const networkId = await web3.eth.net.getId();
      setNetwork(networkId);
    } catch (err) {
      setConnectWalletErrorMessage(
        "Failed to connect to wallet: " + err.message
      );
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      marginBottom: "10vh",
      color: "var(--accent-text)"
    },
    buttonContainer: {
      marginBottom: "10vh",
      minHeight: 50 // Add a fixed height to prevent component movement
    },
    button: {
      margin: "8px 20px", // Add space between buttons
      cursor: "pointer",
      border: "none",
      background: "linear-gradient(45deg, #337ab7, #23527c)", // Blueish gradient background
      color: "#fff",
      fontSize: "1rem", // Slightly smaller font size for unselected buttons
      padding: "15px 30px", // Padding adjusted for smaller size
      borderRadius: "25px", // Rounded corners
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Slight shadow
      transition: "background-color 0.3s, transform 0.2s, box-shadow 0.2s",
      outline: "none",
      fontFamily: "'Arial Black', Gadget, sans-serif", // Bold font
      letterSpacing: "1px", // Slightly increased letter spacing
      opacity: 0.7 // Slightly transparent
    },
    activeButton: {
      fontWeight: "bold",
      background: "linear-gradient(45deg, #ff9900, #ff6600)", // Different gradient for active button
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)", // More pronounced shadow
      transform: "scale(1.2)", // Slightly larger
      fontSize: "1.3rem", // Slightly bigger font size for selected buttons
      opacity: 0.8,
      backgroundColor: "#FFA500"
    },
    whitelistScreenContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    checkReferralScreenContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    input: {
      marginBottom: 15,
      padding: "10px 15px",
      fontSize: "1rem",
      border: "1px solid #ccc",
      borderRadius: "5px",
      width: "300px"
    },
    buttonSubmit: {
      marginTop: 30,
      marginBottom: 10,
      padding: "12px 24px",
      fontSize: "1.2rem",
      borderRadius: "25px",
      border: "2px solid #ffcc00", // Yellow border to match a duck theme
      background: "linear-gradient(45deg, #ffcc00, #ff9900)", // Gradient background
      color: "#fff",
      cursor: "pointer",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      transition: "background-color 0.3s, transform 0.2s",
      outline: "none",
      textDecoration: "none",
      display: "inline-block",
      textAlign: "center",
      lineHeight: "1",
      textTransform: "uppercase",
      fontFamily: "'Arial Black', Gadget, sans-serif", // Bold font
      letterSpacing: "1px" // Slightly increased letter spacing
    },
    referralLink: {
      marginTop: 20,
      padding: "30px",
      backgroundColor: "#e3eaa7",
      borderRadius: "5px",
      fontSize: "1rem",
      color: "black",
      fontFamily: "'Courier New', monospace"
    },
    referralsList: {
      marginTop: 20,
      textAlign: "left"
    },
    successMessage: {
      color: "#4caf50", // Green color for success
      fontSize: "1rem",
      marginTop: "10px",
      fontFamily: "'Courier New', monospace"
    },
    errorMessage: {
      color: "#ff6666", // Light red color for errors
      fontSize: "1rem",
      marginTop: "10px",
      fontFamily: "'Courier New', monospace"
    },
    zeroCounterContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "15px",
      marginBottom: "15px"
    },
    zeroCounterImage: {
      width: "50px",
      marginLeft: "20px"
    },
    referralsContainer: {},
    referralPercentage: {
      fontSize: "22px",
      color: "blue",
      fontWeight: "bold"
    },
    referredAddressesCount: {
      fontSize: "30px",
      fontFamily: "'Courier New', monospace",
      fontWeight: "bold"
    },
    referredAddressesText: {
      marginBottom: "15px",
      fontFamily: "'Courier New', monospace"
    },
    yourReferralLinkText: {
      fontSize: "20px"
    },
    referralLinks: {
      textDecoration: "none",
      color: "#8971d0",
      padding: "5px",
      border: "1px solid #7f8581",
      fontSize: "20px"
    },
    inputTitle: {
      marginBottom: "10px",
      fontSize: "25px",
      fontFamily: "'Courier New', monospace"
    },
    followTwitter: {
      marginTop: "15px",
      marginBottom: "30px",
      fontFamily: "'Courier New', monospace"
    },
    twitterLink: {
      color: "gold",
      fontSize: "20px"
    },
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    yourReferralLinkContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    },
    copyButton: {
      padding: "5px",
      fontFamily: "'Courier New', monospace",
      fontSize: "20px",
      width: "90px",
      marginLeft: "-1px",
      boxShadow: "0 4px 4px rgba(0, 0, 0, 0.2)" // Slight shadow
    }
  };

  const screens = {
    whitelist: {
      buttonText: "Get Whitelisted",
      message: (
        <div style={styles.whitelistScreenContainer}>
          <p style={styles.inputTitle}>Your Solana address:</p>
          <FloatingLabelInput
            type="text"
            placeholder="Enter your Solana address"
            value={solanaAddress}
            onChange={(e) => setSolanaAddress(e.target.value)}
            readOnly={isSubmitted}
          />
          <p style={styles.followTwitter}>
            Make sure you follow our twitter:{" "}
            <a
              href={`${constants.X_URL}${constants.SOCIAL.X_USERNAME}`}
              style={styles.twitterLink}
              target="_blank"
              rel="noreferrer"
            >
              BattleAcademyDucks
            </a>
          </p>
          <p style={styles.inputTitle}>Your Twitter handle:</p>
          <FloatingLabelInput
            type="text"
            placeholder="Enter your Twitter handle @"
            value={twitterHandle}
            onChange={(e) => setTwitterHandle(e.target.value)}
            readOnly={isSubmitted}
          />
          {recaptchaVisible && (
            <ReCAPTCHA
              sitekey="6Ld3ivApAAAAADgwY7vl2ra3dCjxBDlJUSPEutlp"
              onChange={recaptchaHandler}
            />
          )}
          {!isSubmitted && (
            <button
              onClick={handleSubmit}
              style={styles.buttonSubmit}
              className="submitButton"
            >
              Submit
            </button>
          )}
          {whitelistMessage && (
            <p style={styles.errorMessage}>{whitelistMessage}</p>
          )}
          {whitelistReferralLink && (
            <div style={styles.referralLink}>
              <p>
                Use your referral Link to raise your chances to get a whitelist
                and to get <span style={styles.referralPercentage}>30%</span>{" "}
                Solana back for every item your referrals mint!
              </p>
              <div style={styles.zeroCounterContainer}>
                <p>Your total referral count: </p>
                <img
                  src={zeroCounterImage}
                  alt={""}
                  style={styles.zeroCounterImage}
                />
              </div>
              <div style={styles.yourReferralLinkContainer}>
                <p>
                  <span style={styles.yourReferralLinkText}>
                    Your referral link:{" "}
                  </span>
                  <a
                    href={whitelistReferralLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.referralLinks}
                  >
                    {whitelistReferralLink}
                  </a>
                </p>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(whitelistReferralLink);
                    setReferralLinkCopied(true);
                    setTimeout(() => {
                      setReferralLinkCopied(false);
                    }, 2000); // Reset copied state after 2 seconds
                  }}
                  style={styles.copyButton}
                >
                  {referralLinkCopied ? "Copied!" : "Copy"}
                </button>
              </div>
            </div>
          )}
        </div>
      )
    },
    referrals: {
      buttonText: "Check Referrals",
      message: (
        <div style={styles.referralsContainer}>
          <WhitelistChecker
            address={solanaAddress}
            isWhitelisted={isAddressWhitelisted}
          />
          <Label
            text={"Connect wallet to get whitelisted"}
            fontSize={12}
            color={palette.white}
            align={"center"}
          />
          <Spacer size={50} />

          {/* TODO: check if we need below code */}
          {/* <div>
            {account ? (
              <div>
                <p className="whitelist-account-p">
                  Connected Account: {account}
                </p>
                <p className="whitelist-account-p">Network ID: {network}</p>
              </div>
            ) : (
              <button
                className="whitelist-account-button"
                onClick={connectWallet}
              >
                Connect Wallet
              </button>
            )}
            {connectWalletErrorMessage && (
              <p className="whitelist-account-error">
                {connectWalletErrorMessage}
              </p>
            )}
          </div> */}

          <VStack gap={8} mt={16}>
            <Heading>Select a Wallet</Heading>
            <Wallets />
          </VStack>

          <Spacer small />
          <ReferralLink referralLink={referralLink} />
          <SocialMediaShare referralLink={referralLink} />
        </div>
      )
    }
  };

  const handleScreenChange = (screen) => {
    setActiveScreen(screen);
    // setWhitelistMessage("");  // Clear message when screen changes
    // setReferralMessage("");  // Clear message when screen changes
  };

  return (
    <div id="whitelist" style={styles.container}>
      <div style={styles.buttonContainer}>
        {Object.keys(screens).map((screenKey) => (
          <button
            key={screenKey}
            style={{
              ...styles.button,
              ...(activeScreen === screenKey && styles.activeButton)
            }}
            onClick={() => handleScreenChange(screenKey)}
            className="whitelistScreenButtons"
          >
            {screens[screenKey].buttonText}
          </button>
        ))}
      </div>
      <div style={styles.messageContainer}>
        <div>{screens[activeScreen].message}</div>
      </div>
    </div>
  );
}
