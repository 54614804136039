import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Mint() {
  const toBeAnnouncedTitle = "MINT";
  const toBeAnnouncedText = "MINT DATE: To be announced";

  const styles = {
    container: {
      height: window.screen?.height,
      display: "flex",
      textAlign: "center",
      color: "var(--accent-text)",
      paddingTop: 40
    },
    textContainer: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "auto",
      marginBottom: "auto"
    },
    toBeAnnouncedTitle: {
      fontSize: "var(--title-size)",
      fontWeight: "var(--title-weight)",
      fontFamily: "var(--title-font)"
    },
    toBeAnnouncedSubtitle: {
      fontSize: 40,
      fontFamily: "var(--subtitle-font)"
    }
  };

  return (
    <div id="mint" style={styles.container}>
      <div style={styles.textContainer}>
        {/* <h2 style={styles.toBeAnnouncedTitle}>{toBeAnnouncedTitle}</h2> */}
        <p style={styles.toBeAnnouncedSubtitle}>{toBeAnnouncedText}</p>
      </div>
    </div>
  );
}
