import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, get, query, orderByChild, equalTo, update } from "firebase/database";
import firebaseConfig from "./firebaseConfig";



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

// Function to check if Solana address exists
const solanaAddressExists = async (solanaAddress) => {
  const solanaQuery = query(ref(database, "entries"), orderByChild("solanaAddress"), equalTo(solanaAddress));
  const snapshot = await get(solanaQuery);
  return snapshot.exists();
};

// Function to check if Twitter handle exists
const twitterHandleExists = async (twitterHandle) => {
  const twitterQuery = query(ref(database, "entries"), orderByChild("twitterHandle"), equalTo(twitterHandle));
  const snapshot = await get(twitterQuery);
  return snapshot.exists();
};

// Function to add data to the database
const addToDatabase = async (solanaAddress, twitterHandle, deviceInfo, timestamp, referralLink, referredFrom) => {
  if (solanaAddress && twitterHandle && deviceInfo && timestamp) {
    // Check for duplicates
    const solanaExists = await solanaAddressExists(solanaAddress);
    const twitterExists = await twitterHandleExists(twitterHandle);

    if (solanaExists && twitterExists) {
      return "Solana address and Twitter handle are already submitted!";
    } else if (solanaExists) {
      return "This Solana address is already submitted!";
    } else if (twitterExists) {
      return "This Twitter handle is already submitted!";
    }

    // Push data to the database
    const newEntryRef = push(ref(database, "entries"));
    const entryId = newEntryRef.key;
    
    await update(newEntryRef, {
      solanaAddress,
      twitterHandle,
      deviceInfo,
      timestamp,
      referralLink,
      referredFrom
    }).catch(error => {
      console.error("Error writing to database:", error);
      return "Error writing to database";
    });

    return "Submitted successfully!";
  } else {
    return "Solana address, Twitter handle, device info, and timestamp are required.";
  }
};

const getReferralCount = async (referralLink) => {
  const database = getDatabase();
  const entriesRef = ref(database, "entries");
  const referredFromQuery = query(entriesRef, orderByChild("referredFrom"), equalTo(referralLink));
  const snapshot = await get(referredFromQuery);
  return snapshot.exists() ? snapshot.size : 0;
};

const getReferralLink = async (solanaAddress) => {
  const database = getDatabase();
  const entriesRef = ref(database, "entries");
  const solanaQuery = query(entriesRef, orderByChild("solanaAddress"), equalTo(solanaAddress));
  const snapshot = await get(solanaQuery);
  
  if (snapshot.exists()) {
    const entryData = snapshot.val();
    const entryKey = Object.keys(entryData)[0];  // Assuming only one entry per Solana address
    const { referralLink } = entryData[entryKey];
    return referralLink;
  } else {
    return null;
  }
};

export { addToDatabase, getReferralCount, getReferralLink };