import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  useDisclosure,
  Button,
  Flex
} from "@chakra-ui/react";

const ConnectWalletModal = ({ modalBody }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex justify="center" align="center">
        <Button colorScheme="teal" onClick={onOpen}>
          Connect Wallet
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="lg" p={6}>
          <ModalHeader>
            <Heading fontSize="2xl" textAlign="center" color="teal.600">
              Connect to a Wallet
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modalBody}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConnectWalletModal;
