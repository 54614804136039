import blockchainReducer from "../redux/blockchain/blockchainReducer";
import dataReducer from "../redux/data/dataReducer";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    blockchain: blockchainReducer,
    data: dataReducer
  }
});

export default store;
