import React, { useState, useEffect } from "react";

export default function Start({ menuToggleShow }) {
  const [blink, setBlink] = useState(false);
  const [blinkCount, setBlinkCount] = useState(0);
  const [blinkStarted, setBlinkStarted] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);

  useEffect(() => {
    const handleBlinking = () => {
      if (blinkStarted && blinkCount < 3) {
        setBlink((prevBlink) => !prevBlink);
        setBlinkCount((prevCount) => prevCount + 1);
      } else if (blinkCount === 3) {
        clearInterval(blinkInterval);
      }
    };

    const blinkInterval = setInterval(handleBlinking, 500); // Adjust blinking speed here

    return () => clearInterval(blinkInterval);
  }, [blinkCount, blinkStarted]);

  useEffect(() => {
    const handleWebsiteLoad = () => {
      // Start blinking after website is fully loaded
      setBlinkStarted(true);
      // Show the button
      setButtonVisible(true);
    };

    window.addEventListener("load", handleWebsiteLoad);

    return () => window.removeEventListener("load", handleWebsiteLoad);
  }, []);

  const logo = "/logo";

  const description =
    "Battle Academy Ducks is a 7,777 ERC-721 hand-drawn art collection on the Ethereum blockchain, inspired by anime culture.";

  const styles = {
    verticalAlignment: {
      marginTop: "auto",
      marginBottom: "auto",
      marginLeft: "auto",
      marginRight: "auto"
    },
    description: {
      fontSize: "var(--description-size)",
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
      width: "60%",
      fontFamily: "var(--description-font)",
      color: "#fff",
      opacity: menuToggleShow ? 0.2 : 1
    },
    button: {
      display: buttonVisible ? "block" : "none",
      margin: "100px auto",
      padding: "10px 20px",
      fontSize: "26px",
      cursor: "pointer",
      backgroundColor: "#FFA500", // Change button color to a bold orange
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      textAlign: "center",
      border: "2px solid #fff", // Add a white border
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
      animation: blinkCount < 2 ? "blink-animation 0.4s infinite" : "none" // Adjust animation duration here
    }
  };

  const scrollToMint = () => {
    const element = document.getElementById("whitelist");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="start">
      <div id="start-container">
        <p style={styles.description}>{description}</p>
        <button
          style={styles.button}
          onClick={scrollToMint}
          className="whitelistButton"
        >
          Get Whitelisted!
        </button>
      </div>
    </div>
  );
}
