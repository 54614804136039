import React, { useEffect, useState } from "react";
import Label from "./Label";
import WhitelistChecker from "./WhitelistChecker";
import palette from "../styles/palette";
import Spacer from "./Spacer";
import { Button, Heading, VStack } from "@chakra-ui/react";
import Wallets from "./Wallets";
import ReferralLink from "./ReferralLink";
import SocialMediaShare from "./SocialMediaShare";
import ConnectWalletModal from "../modals/ConnectWalletModal";

export const ReferralSection = () => {
  const [solanaAddress, setSolanaAddress] = useState("");
  const [isAddressWhitelisted, setIsAddressWhitelisted] = useState(null);
  const [referralLink, setReferralLink] = useState("mock-referral-link.com");

  useEffect(() => {
    const checkWhitelistStatus = async () => {
      try {
        const whitelisted = await false; // TODO : put function here that will check if the address is whitelisted
        setIsAddressWhitelisted(whitelisted);
      } catch (error) {
        console.error("Error checking whitelist status:", error);
        setIsAddressWhitelisted(false); // ? Assume not whitelisted in case of error
      }
    };

    if (solanaAddress) {
      checkWhitelistStatus();
    }
  }, [solanaAddress]);

  const modalBody = (
    <VStack gap={8} mt={16}>
      <Heading>Select a Wallet</Heading>
      <Wallets />
    </VStack>
  );

  return (
    <div>
      <WhitelistChecker
        address={solanaAddress}
        isWhitelisted={isAddressWhitelisted}
      />
      <Label
        text={"Connect wallet to get whitelisted"}
        fontSize={12}
        color={palette.white}
        align={"center"}
      />
      <Spacer small />

      <ConnectWalletModal modalBody={modalBody} />

      <Spacer size={50} />
      <ReferralLink referralLink={referralLink} />
      <SocialMediaShare referralLink={referralLink} />
    </div>
  );
};
