import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Logo({}) {
  const profilePic = "/assets/logo.png";

  const styles = {
    image: {
      width: "290px",
      filter: "drop-shadow(3px 1px 16px #fff)",
      marginBottom: "30px"
    }
  };

  return (
    <div id={"profile-pic-container"}>
      <img src={profilePic} style={styles.image} alt={""} />
    </div>
  );
}
