import React from "react";
import { Box, Text, Button, VStack, Heading } from "@chakra-ui/react";
import { BsTwitterX } from "react-icons/bs";

export const FollowOnTwitter = ({ twitterUrl, twitterHandle }) => {
  return (
    <Box
      bg="blue.50"
      p={6}
      borderRadius="md"
      boxShadow="md"
      textAlign="center"
      width={["100%", "75%", "50%"]} // Full width on mobile, 75% on tablet, 50% on desktop
      mx="auto" // Centers the Box horizontally
    >
      <VStack spacing={4}>
        <Heading as="h2" size="lg" color="blue.600">
          Make sure to follow us on X
        </Heading>
        <Text fontSize="md" color="gray.600">
          Stay up to date with the latest updates and news.
        </Text>
        <Button
          as="a"
          href={twitterUrl}
          target="_blank"
          colorScheme="blackAlpha"
          leftIcon={<BsTwitterX className="x-icon" />}
          size="lg"
        >
          Follow @{twitterHandle}
        </Button>
      </VStack>
    </Box>
  );
};
