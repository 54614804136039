// src/database/firebaseConfig.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBsYnVLydsfQl7dFAA7ODWZOdO0-Lptqzs",
  authDomain: "battle-academy-ducks.firebaseapp.com",
  projectId: "battle-academy-ducks",
  storageBucket: "battle-academy-ducks.appspot.com",
  messagingSenderId: "882808625086",
  appId: "1:882808625086:web:aa2c73ea5e09d455424bef",
  measurementId: "G-M0YVPQXM3N",
  databaseURL: "https://battle-academy-ducks-default-rtdb.europe-west1.firebasedatabase.app"
};

export default firebaseConfig;
