import React from "react";
import palette from "../styles/palette";

export default function Label({
  text,
  color = palette.black,
  fontSize = 16,
  fontWeight = 400,
  align = "left",
  className
}) {
  return (
    <p
      className={`${styles.label} ${className}`}
      style={{
        color,
        fontSize,
        fontWeight
      }}
      align={align}
    >
      {text}
    </p>
  );
}

const styles = {
  label: {
    fontSize: "16px",
    color: palette.black,
    fontFamily: "Noto Sans",
    fontWeight: 400,
    margin: 0,
    padding: 0,
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto"
  }
};
