import React, { useState } from "react";
import { FaCopy } from "react-icons/fa";

const ReferralLink = ({ referralLink }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(referralLink).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
    });
  };

  return (
    <div
      onClick={handleCopyClick}
      style={styles.container}
      title="Click to copy"
    >
      <span style={styles.linkText}>{referralLink}</span>
      <FaCopy style={styles.icon} />
      {copied && <span style={styles.copiedText}>Copied!</span>}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    fontSize: "16px",
    color: "#6c757d",
    backgroundColor: "#f8f9fa",
    border: "1px solid #ced4da",
    borderRadius: "4px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    position: "relative",
    width: "fit-content",
    marginLeft: "auto",
    marginRight: "auto"
  },
  linkText: {
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  icon: {
    marginLeft: "10px",
    color: "#007bff",
    width: "21px",
    height: "21px"
  },
  copiedText: {
    marginLeft: "10px",
    fontSize: "14px",
    color: "green"
  }
};

export default ReferralLink;
