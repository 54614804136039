import React from "react";

const WhitelistChecker = ({ address, isWhitelisted }) => {
  return (
    <div style={styles.whitelistChecker}>
      <p style={styles.userAddress}>{address}</p>
      {isWhitelisted === null ? (
        <p style={{ ...styles.statusMessage, ...styles.checking }}>
          Checking whitelist status...
        </p>
      ) : isWhitelisted ? (
        <p style={{ ...styles.statusMessage, ...styles.whitelisted }}>
          Whitelisted
        </p>
      ) : (
        <p style={{ ...styles.statusMessage, ...styles.notWhitelisted }}>
          Not whitelisted
        </p>
      )}
    </div>
  );
};

const styles = {
  whitelistChecker: {
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    maxWidth: "400px",
    margin: "0 auto",
    backgroundColor: "#f9f9f9"
  },
  userAddress: {
    fontSize: "16px",
    color: "#333",
    marginBottom: "10px",
    wordBreak: "break-all" // Ensures the address wraps properly if it's too long
  },
  statusMessage: {
    fontSize: "18px",
    fontWeight: "bold",
    margin: 0,
    padding: "10px",
    borderRadius: "4px"
  },
  checking: {
    color: "#999"
  },
  whitelisted: {
    color: "#28a745",
    backgroundColor: "#e6ffe6"
  },
  notWhitelisted: {
    color: "#dc3545",
    backgroundColor: "#ffe6e6"
  }
};

export default WhitelistChecker;
